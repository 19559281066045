<template>
    <div class="comment-ctn">
        <div style="margin-top: 10px; display: flex; font-size: 16px;">
            <div style="color: rgba(0, 0, 0, 0.45); flex: 1;">
                回复于 {{  commentData.addDateTime  }}
            </div>
            <div title="回复" class="reply-icon"
                style="display: flex; align-items: center; cursor: pointer; opacity: 0.8; color: rgba(0, 0, 0, 0.65);"
                @click="handleCommentClick">
                <svg-icon name="comment-alt" class="operatin-icon comment-icon" style="fill: rgba(0, 0, 0, 0.65);;"
                    @click="handleCommentClick"></svg-icon>
                <div style="margin-left: 6px;">回复</div>
            </div>
        </div>
        <div v-show="isInputShow" style="margin-top: 8px;">
            <a-spin :spinning="isCommentSubmitting">
                <a-input type="textarea" v-model="commentContent" placeholder="输入评论" ref="inputRef"
                    @blur="isCommentClick = false" @focus="isCommentClick = true"> </a-input>
                <div style="text-align: right; margin-top: 12px;">
                    <a-button type="primary" :disabled="!commentContent.toString().trim()" :loading="isCommentSubmitting"
                        @click="handleSubmitComment"> 发布 </a-button>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
import { computed, ref, nextTick } from 'vue-demi';
import { replyComment } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
export default {
    props: {
        commentData: {
            default: () => ({})
        }
    },
    setup(props, context) {
        const isCommentClick = ref(false);
        const commentContent = ref('');

        const isInputShow = computed(() => {
            return isCommentClick.value || commentContent.value;
        })

        const isCommentSubmitting = ref(false);

        const handleCommentClick = () => {
            isCommentClick.value = true;
            nextTick(() => {
                inputRef.value && inputRef.value.focus();
            })

        }

        const inputRef = ref();

        // 提交评论
        const handleSubmitComment = async () => {
            isCommentSubmitting.value = true;
            const res = await replyComment({
                "paperId": props.commentData.paperId,
                "commentId": props.commentData.commentPaperId,
                "content": commentContent.value
            });
            if (res.success) {
                message.success('评论成功');
                context.emit('onComment')
                commentContent.value = '';
                isCommentClick.value = false;
            }
            isCommentSubmitting.value = false;
        }
        return {
            isCommentClick,
            commentContent,
            isInputShow,
            isCommentSubmitting,
            handleCommentClick,
            inputRef,
            handleSubmitComment,

        }
    }
}
</script>

<style lang="less" scoped>
.comment-ctn {

    /deep/ .ant-input {
        background: #EBEBEB;

        &:focus {
            background: white;
        }

    }

    
}
</style>